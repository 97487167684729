.alert-container{
    position: fixed;
    top: 90%;
    width: 100%;
    z-index: 1000;
    display: flex;
    justify-content: center;
}
.asante-alert{
}
