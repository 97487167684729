
.display-inline{
    display: inline-block;
}

.go-right{
    float: right;
    width: 50%;
}

.go-left{
    float: left;
    width: 45%;
}

.copy-button{
    padding: 0;
    background-color: rgba(0,0,0,0);
}
