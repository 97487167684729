body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


.braille-area{
    min-height: 200px;
    border-style: none;
}

.braille-area:focus{
    box-shadow: none;
}
.braille-card{
    margin-top: 20px;
}

.card-title{
    font-weight: 300;
    margin-top: 5px;
    margin-left: 5px;
}

.card-text{
    margin-left: 15px;
    font-size: 17px;
    white-space: pre-line;
    font-weight: 300;
}

.card-border{
    border-color: rgb(33, 83, 150);
    border-width: medium;
}

.alert-container{
    position: fixed;
    top: 90%;
    width: 100%;
    z-index: 1000;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}
.asante-alert{
}


.display-inline{
    display: inline-block;
}

.go-right{
    float: right;
    width: 50%;
}

.go-left{
    float: left;
    width: 45%;
}

.copy-button{
    padding: 0;
    background-color: rgba(0,0,0,0);
}

@font-face {
  font-family: "California Braille";
  src: url(/static/media/CACompliantBrailleOsixO.ce894922.woff) format("woff")
}

.header-title{
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.header-img{
    height: 3.5rem;
    margin-right: 10px;
}

.large-header{
    font-size: 3.5em;
    font-weight: 200;
}

.sub-header{
    font-size: 1.2em;
    font-weight: 300;
}

.sub-title{
    font-size: 3em;
    font-weight: 200;
}

@media only screen and (max-width: 1000px){
    .large-header{
        font-size: 3.2em;
    }

    .sub-header{
        font-size: 1.0em;
    }

    .sub-title{
        font-size: 2.75em;
    }

    .header-img{
        height: 3.2rem;
        margin-right: 5px;
    }

}

@media only screen and (max-width: 700px){
    .large-header{
        font-size: 2.4em;
    }

    .sub-title{
        font-size: 2em;
    }

    .sub-header{
        font-size: 0.8em;
    }

    .header-img{
        height: 2.4rem;
        margin-right: 0px;
    }

}


@media only screen and (max-width: 450px){
    .large-header{
        font-size: 2em;
    }

    .sub-header{
        font-size: 0.8em;
    }

    .sub-title{
        font-size: 1.75em;
    }

    .header-img{
        height: 2rem;
        margin-right: 0px;
    }

}



.asante-text{
    color: rgb(33, 83, 150);
}

.sub-color{
    color: hsla(0, 0%, 50%, 1);
}

#brailleFont{
    font-family: "California Braille";
}


.footer{
    margin-top: 100px;

}


.no-side-padding{
    padding-left: 0px;
    padding-right: 0px;
}

.no-side-margin{
    margin-left: 0px;
    margin-right: 0px;
}

