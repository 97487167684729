
.braille-area{
    min-height: 200px;
    border-style: none;
}

.braille-area:focus{
    box-shadow: none;
}
.braille-card{
    margin-top: 20px;
}

.card-title{
    font-weight: 300;
    margin-top: 5px;
    margin-left: 5px;
}

.card-text{
    margin-left: 15px;
    font-size: 17px;
    white-space: pre-line;
    font-weight: 300;
}

.card-border{
    border-color: rgb(33, 83, 150);
    border-width: medium;
}
