
.no-side-padding{
    padding-left: 0px;
    padding-right: 0px;
}

.no-side-margin{
    margin-left: 0px;
    margin-right: 0px;
}
