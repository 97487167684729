@font-face {
  font-family: "California Braille";
  src: url("../fonts/CACompliantBrailleOsixO.woff") format("woff")
}

.header-title{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.header-img{
    height: 3.5rem;
    margin-right: 10px;
}

.large-header{
    font-size: 3.5em;
    font-weight: 200;
}

.sub-header{
    font-size: 1.2em;
    font-weight: 300;
}

.sub-title{
    font-size: 3em;
    font-weight: 200;
}

@media only screen and (max-width: 1000px){
    .large-header{
        font-size: 3.2em;
    }

    .sub-header{
        font-size: 1.0em;
    }

    .sub-title{
        font-size: 2.75em;
    }

    .header-img{
        height: 3.2rem;
        margin-right: 5px;
    }

}

@media only screen and (max-width: 700px){
    .large-header{
        font-size: 2.4em;
    }

    .sub-title{
        font-size: 2em;
    }

    .sub-header{
        font-size: 0.8em;
    }

    .header-img{
        height: 2.4rem;
        margin-right: 0px;
    }

}


@media only screen and (max-width: 450px){
    .large-header{
        font-size: 2em;
    }

    .sub-header{
        font-size: 0.8em;
    }

    .sub-title{
        font-size: 1.75em;
    }

    .header-img{
        height: 2rem;
        margin-right: 0px;
    }

}



.asante-text{
    color: rgb(33, 83, 150);
}

.sub-color{
    color: hsla(0, 0%, 50%, 1);
}

#brailleFont{
    font-family: "California Braille";
}
